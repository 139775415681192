import request from '@/utils/request'

export function WeGetServiceList(query) {
  return request({
    url: 'PensionService/WeGetServiceList',
    method: 'get',
    params: query,
  })
}

export function SaveCheck(data) {
  return request({
    url: 'Vaccine/SaveCheck',
    method: 'post',
    data,
  })
}

export function WeGetProServicetOfManPage(query) {
  return request({
    url: 'ProService/WeGetProServicetOfManPage',
    method: 'get',
    params: query,
  })
}
export function WeGetSerSortOfManList(query) {
  return request({
    url: 'ProService/WeGetSerSortOfManList',
    method: 'get',
    params: query,
  })
}
export function WeGetSerPackageList(query) {
  return request({
    url: 'SerPackage/WeGetSerPackageList',
    method: 'get',
    params: query,
  })
}

export function WeGenerateOrder(data) {
  return request({
    url: 'Order/WeGenerateOrder',
    method: 'post',
    data,
  })
}

export function WeGetOrderServiceList(query) {
  return request({
    url: 'Order/WeGetOrderServiceList',
    method: 'get',
    params: query,
  })
}

export function WeSaveOrderService(data) {
  return request({
    url: 'Order/WeSaveOrderService',
    method: 'post',
    data,
  })
}
export function WePlaceOrder(data) {
  return request({
    url: 'Order/WePlaceOrder',
    method: 'post',
    data,
  })
}

export function WeDeleteOrderService(data) {
  return request({
    url: 'Order/WeDeleteOrderService',
    method: 'post',
    data,
  })
}

export function WeSaveOrderPackage(data) {
  return request({
    url: 'Order/WeSaveOrderPackage',
    method: 'post',
    data,
  })
}

export function WeGetMyOrderPage(query) {
  return request({
    url: 'Order/WeGetMyOrderPage',
    method: 'get',
    params: query,
  })
}

export function WeRevokeOrder(data) {
  return request({
    url: 'Order/WeRevokeOrder',
    method: 'post',
    data,
  })
}

export function WeDeleteOrder(data) {
  return request({
    url: 'Order/WeDeleteOrder',
    method: 'post',
    data,
  })
}

export function WeEvaluateOrder(data) {
  return request({
    url: 'ServiceEval/WeEvaluateOrder',
    method: 'post',
    data,
  })
}
// 获取养老服务套餐详情
export function WeGetSerPackage(query) {
  return request({
    url: 'SerPackage/WeGetSerPackage',
    method: 'get',
    params: query,
  })
}

// 获取养老服务项目详情
export function WeGetProServicetOfMan(query) {
  return request({
    url: 'ProService/WeGetProServicetOfMan',
    method: 'get',
    params: query,
  })
}
// 获取我的订单详情
export function WeGetMyOrder(query) {
  return request({
    url: 'ShopOrder/WeGetMyOrder',
    method: 'get',
    params: query,
  })
}

// 获取绑定信息
export function GetMyOldManInfo(query) {
  return request({
    url: 'Plat/GetMyOldManInfo',
    method: 'get',
    params: query,
  })
}

// 获取绑定信息
export function WeGetMyRegInfo(query) {
  return request({
    url: 'BindInfo/WeGetMyBindInfoPage',
    method: 'get',
    params: query,
  })
}

// 获取我绑定的老人信息列表
export function WeGetMyBindInfoPage(query) {
  return request({
    url: 'BindInfo/WeGetMyBindInfoPage',
    method: 'get',
    params: query,
  })
}
//保存绑定老人信息，类似于美团添加收货人信息
export function WeSaveBindInfo(data) {
  return request({
    url: 'BindInfo/WeSaveBindInfo',
    method: 'post',
    data,
  })
}

// 获取当前居安养用户所在社区的服务机构数量
export function WxGetSerOrganCount(query) {
  return request({
    url: 'SerOrgan/WxGetSerOrganCount',
    method: 'get',
    params: query,
  })
}
// 获取当前居安养用户所在社区的服务机构列表
export function WxGetSerOrganList(query) {
  return request({
    url: 'SerOrgan/WxGetSerOrganList',
    method: 'get',
    params: query,
  })
}
// 获取获取养老服务项目列表,居安养小程序使用
export function WeGetProServicetPage(query) {
  return request({
    url: 'ProService/WeGetProServicetPage',
    method: 'get',
    params: query,
  })
}

// 获取订单详情
export function WxGetShopOrder(query) {
  return request({
    url: 'ShopOrder/WxGetShopOrder',
    method: 'get',
    params: query,
  })
}

// 获取订单商品列表，居安端居民使用
export function WeGetOrderGoodsList(query) {
  return request({
    url: 'ShopOrder/WeGetOrderGoodsList',
    method: 'get',
    params: query,
  })
}
